export const experienceData = [
    {
        id: 1,
        company: 'Phillips Edison & Company',
        jobtitle: 'Enterprise Apps Intern',
        startYear: 'Aug 2022',
        endYear: 'Dec 2022'
    },
    {
        id: 2,
        company: 'Phillips Edison & Company',
        jobtitle: 'Enterprise Apps Intern',
        startYear: 'May 2023',
        endYear: 'Aug 2023'
    },
    {
        id: 3,
        company: 'Phillips Edison & Company',
        jobtitle: 'Enterprise Apps Intern',
        startYear: 'May 2024',
        endYear: 'Aug 2024'
    },
]